/* cairo-regular - arabic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/cairo/cairo-v22-arabic_latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/cairo/cairo-v22-arabic_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/cairo/cairo-v22-arabic_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/cairo/cairo-v22-arabic_latin-regular.svg#Cairo') format('svg'); /* Legacy iOS */
}

/* cairo-500 - arabic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/cairo/cairo-v22-arabic_latin-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/cairo/cairo-v22-arabic_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/cairo/cairo-v22-arabic_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/cairo/cairo-v22-arabic_latin-500.svg#Cairo') format('svg'); /* Legacy iOS */
}

/* cairo-600 - arabic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/cairo/cairo-v22-arabic_latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/cairo/cairo-v22-arabic_latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/cairo/cairo-v22-arabic_latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/cairo/cairo-v22-arabic_latin-600.svg#Cairo') format('svg'); /* Legacy iOS */
}

/* cairo-700 - arabic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/cairo/cairo-v22-arabic_latin-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/cairo/cairo-v22-arabic_latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/cairo/cairo-v22-arabic_latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/cairo/cairo-v22-arabic_latin-700.svg#Cairo') format('svg'); /* Legacy iOS */
}

/* cairo-800 - arabic_latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/cairo/cairo-v22-arabic_latin-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/cairo/cairo-v22-arabic_latin-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/cairo/cairo-v22-arabic_latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-800.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/cairo/cairo-v22-arabic_latin-800.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/cairo/cairo-v22-arabic_latin-800.svg#Cairo') format('svg'); /* Legacy iOS */
}
