/* Styling of overlay */
.bm-overlay {
    top: 0;
    right: 0;
    left: 0;
    background: rgb(255, 255, 255) !important;
}

.bm-menu-wrap {
    top: 0;
    right: 0;
    left: 0;
}
